const getIndicatorSetFormInitialValues = (indicatorSet) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const todayISODate = today.toISOString().split('T')[0]

  const {
    title = '',
    report_date = todayISODate,
    f1_1 = '0',
    f1_notes = '',
    f2_1a = '0',
    f2_1b = '0',
    f2_2a = '0',
    f2_2b = '0',
    f2_3a = '0',
    f2_3b = '0',
    f2_4 = '0',
    f2_5 = '0',
    f2_notes = '',
    f3_1 = '0',
    f3_2 = '0',
    f3_3 = '0',
    f3_4 = '0',
    f3_5a = '0',
    f3_5b = '0',
    f3_5c,
    f3_5d,
    f3_6 = '0',
    f3_notes = '',
    f4_1 = '0',
    f4_2 = '0',
    f4_3 = '0',
    f4_1_calc = '0',
    f4_2_calc = '0',
    f4_3_calc = '0',
    f4_start_date = todayISODate,
    f4_end_date = todayISODate,
    f4_notes = '',
    f5_1 = '0',
    f5_2 = '0',
    f5_3 = '0',
    f5_4a = '0',
    f5_4b = '0',
    f5_4c,
    f5_4d,
    f5_5 = '0',
    f5_6 = '0',
    f5_notes = '',
    f6_1a = '0',
    f6_1b = '0',
    f6_1c,
    f6_1d,
    f6_notes = '',
    f7_1a = '0',
    f7_1b = '0',
    f7_1c,
    f7_1d,
    f7_2a = '0',
    f7_2b = '0',
    f7_2c,
    f7_2d,
    f7_3 = '0',
    f7_4 = '0',
    f7_notes = '',
  } = indicatorSet ?? {}

  return {
    title,
    report_date,
    f1_1,
    f1_notes,
    f2_1a,
    f2_1b,
    f2_2a,
    f2_2b,
    f2_3a,
    f2_3b,
    f2_4,
    f2_5,
    f2_notes,
    f3_1,
    f3_2,
    f3_3,
    f3_4,
    f3_5a,
    f3_5b,
    f3_5c,
    f3_5d,
    f3_6,
    f3_notes,
    f4_1,
    f4_2,
    f4_3,
    f4_1_calc,
    f4_2_calc,
    f4_3_calc,
    f4_start_date,
    f4_end_date,
    f4_notes,
    f5_1,
    f5_2,
    f5_3,
    f5_4a,
    f5_4b,
    f5_4c,
    f5_4d,
    f5_5,
    f5_6,
    f5_notes,
    f6_1a,
    f6_1b,
    f6_1c,
    f6_1d,
    f6_notes,
    f7_1a,
    f7_1b,
    f7_1c,
    f7_1d,
    f7_2a,
    f7_2b,
    f7_2c,
    f7_2d,
    f7_3,
    f7_4,
    f7_notes,
  }
}

export { getIndicatorSetFormInitialValues }
